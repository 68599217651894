<template lang="pug">
div(v-if="page?.block")
  Header(
    :header="page?.header"
  )
  |
  div(
    v-for="(block, index) in page.block"
    :key="index"
    :id="block?.anchor || `${index}-${block.__typename}`"
  )
    component(
      :is="block.__typename"
      :key="`${index}-${block.__typename}`"
      :block="block"
    )
  |
  div(v-if="page?.seo")
    ComponentSharedSeo(
      :seo="page.seo"
    )
  |
  Footer(
    :footer="page?.footer"
  )
</template>

<script setup lang="ts">
import { useAsyncData } from '#app';

const route = useRoute();

const slug = route.params.slug as string || "";

const { data: page } = await useAsyncData(`page-${slug}`, async () => {
  const pages = await queryCollection("pages").all();

  if (!pages) return null;

  const pSlug = slug ? `/${slug}` : "/";

  return pages[0]?.meta?.body?.find((p) => p.slug === pSlug) || null;
});

// const preventIndexing = computed(() => {
//   return page?.value?.preventIndexing || false;
// });

const adjustScrollForHeader = () => {
  const headerOffset = 82;
  const { hash } = window.location;

  if (!hash) return;

  const targetElement = document.querySelector(hash);

  if (!targetElement) return;

  setTimeout(() => {
    const elementPosition = targetElement.getBoundingClientRect().top + window.scrollY - headerOffset;

    window.scrollTo({
      top: elementPosition,
      behavior: "smooth",
    });
  }, 100);
};

onMounted(() => {
  adjustScrollForHeader();
});

watch(() => route.hash, () => {
  adjustScrollForHeader();
});
</script>